/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-12-01 10:22:01
 * @Description:
 */

import FTEventTarget, { FTEVENT_CONSTANTS } from './FTEventTarget';
import utils, { LANGUAGE_TYPE } from 'src/utils/utils';

import { FTUserStore } from 'src/store';
import { loadSource } from 'src/server/api';

interface Locale {
  zh_CN?: string;
  en_US?: string;
  de_DE?: string;
  es_ES?: string;
  fr_FR?: string;
  ja_JP?: string;
  pt_BR?: string;
  ru_RU?: string;
  zh_TW?: string;
}
class FTLang {
  locale: Locale;
  currentLanguage!: string;
  currentMessage!: string;
  constructor() {
    this.locale = {
      // zh_CN,
      // en_US,
      // de_DE,
      // es_ES,
      // fr_FR,
      // ja_JP,
      // pt_BR,
      // ru_RU,
      // zh_TW,
    };
    this.currentLanguage = utils.getCurrentLanguage();
    this.updateLanguage();
  }

  loadLangJson() {
    return loadSource({
      url: `/json/lang/${this.currentLanguage}.json?v=${process.env.REACT_APP_VERSION}`,
    });
  }

  async updateLanguage(lang?: LANGUAGE_TYPE) {
    (this.currentLanguage as string) = lang || this.currentLanguage;
    let language: any = this.currentLanguage;
    let message: any = this.locale[language];
    if (!message) {
      message = await this.loadLangJson();
      this.locale[language] = message;
    }

    if ((language !== "id_ID") &&
      (language !== LANGUAGE_TYPE.IT_IT) &&
      (language !== LANGUAGE_TYPE.TH_TH) &&
      (language !== LANGUAGE_TYPE.VI_VN) &&
      (language !== LANGUAGE_TYPE.TR_TR) &&
      (language !== LANGUAGE_TYPE.PL_PL) &&
      (language !== LANGUAGE_TYPE.NL_NL)
    ) {
      utils.setCookie("locale", language, 0, ".fotor.com");
    } else {
      const lang: string = utils.getCurrentLanguage();
      utils.setCookie("locale", lang, 0, ".fotor.com");
    }
    this.currentMessage = message;
    document.title =this._("goart - seo - title");
    setTimeout(() => {
      // 写个事件分发一下
      FTEventTarget.getInstance().fireEvent({
        type: FTEVENT_CONSTANTS.CHNAGE_LANGUAGE,
      });
    }, 100)
  }

  /**
   * 获取多语言
   */
  _ = (key: string, values?: any) => {
    let languageValue = this.currentMessage && this.currentMessage[key as any];
    if (
      languageValue &&
      Object.prototype.toString.call(values) === '[object Object]'
    ) {
      for (const i in values) {
        languageValue = languageValue.replace(
          new RegExp(`{${i}}`, 'g'),
          values[i],
        );
      }
    }
    return languageValue || key;
  };
  getSpecialLang = (key: string, lang) => {
    const messages = this.locale[lang];
    return messages && messages[key as any];
  }
}

export default new FTLang();
