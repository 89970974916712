/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-11-29 19:57:59
 * @Description:
 */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Layout.scss";

import { AD_ID, utils, withUnit } from "src/utils";
import {
  DialogOpenApp,
  FTDownloadLoading,
  FTDownloadMobile,
  FTEffectLoading,
  ScriptModal
} from "src/components";
import { FTCanvasStore, FTImageStore, FTUserStore } from "src/store";
import { IconClose, IconVip } from "src/assets";
import React, { useState } from "react";
import { useImageStoreFN, useLanguage } from "src/hooks";

import ADWrapper from "./ADWrapper/ADWrapper";
import FTCenter from "src/pages/Content/FTCenter";
import FTCommonClass from "src/utils/FTCommonClass";
import Header from "src/pages/Headers";
import ReactDOM from "react-dom";
import ToolLeft from "src/pages/ToolLeft/ToolLeft";
import ToolLeftMobile from "src/pages/ToolLeft/ToolLeftMobile";
import { autorun } from "mobx";
import clsx from "clsx";
import { defaultLength } from "src/constant";

export default function Layout() {
  const [loading, setLoading] = React.useState(false);
  const [effectLoading, setEffectLoading] = React.useState(false);
  const [downloadLoading, setDownloadLoading] = React.useState(false);
  const [downloadVisible, setDownloadVisible] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [showDownload, setShowDownload] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState(false);
  const [showAd, setShowAd] = React.useState(true);
  const [showAdInnerWidth, setShowAdInnerWidth] = React.useState(true);
  const [useActivity, setUseActivity] = React.useState<any>("");
  const initAd = React.useRef(false);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const progressUpload = useImageStoreFN((state) => state.progress.upload);
  const progressDownload = useImageStoreFN((state) => state.progress.download);
  const progressTask = useImageStoreFN((state) => state.progress.task);

  const [hasNeedRoot, setHasNeedRoot] = useState(false);
  const { t, language } = useLanguage();
  const totalProgress = React.useMemo(() => {
    const result =
      progressUpload * 0.3 +
      progressDownload * 0.08 +
      progressTask * 0.6 +
      0.02;
    return result;
  }, [progressUpload, progressDownload, progressTask]);

  React.useEffect(() => {
    autorun(() => {
      let { isVip, showCountryAd } = FTUserStore;
      const { currentImage } = FTImageStore;
      const { downloadSuccess } = FTCanvasStore;
      setLoading(FTCanvasStore.loading);
      setDownloadLoading(FTCanvasStore.downloadLoading);
      setDownloadVisible(FTCanvasStore.downloadModalVisible);
      setEffectLoading(FTImageStore.loading);
      setCurrentImage(currentImage);
      setIsMobile(FTCanvasStore.isMobile);
      setShowDownload(FTCanvasStore.showDownload);
      setHasNeedRoot(FTImageStore.checkRoot.need);
      setDownloadSuccess(downloadSuccess);
      if (
        currentImage &&
        !FTCanvasStore.isMobile &&
        !isVip &&
        !showCountryAd &&
        !initAd.current
      ) {
        setShowAd(true);
        testGAReady();
        FTCommonClass.setShowAd(true);
        handleAdHeight();
      } else {
        if (
          ((isVip || showCountryAd) && currentImage) ||
          !currentImage ||
          FTCanvasStore.isMobile
        ) {
          document.dispatchEvent(new CustomEvent("hideAds"));
          setShowAd(false);
          FTCommonClass.setShowAd(false);
          handleAdHeight();
        } else {
          setShowAd(true);
          FTCommonClass.setShowAd(true);
          handleAdHeight();
        }
      }
    });
    computedIsMobile();
    window.addEventListener("resize", computedIsMobile);
    FTUserStore.getUserActivity().then(() => {
      // if(FTUserStore.showFreeTry) {
      //   setUseActivity("try")
      // } else if(FTUserStore.showDiscount) {
      //   setUseActivity("discount")
      // } else {
      //   setUseActivity("")
      // }
    });
    // 請求特效列表
    FTImageStore.getEffectList(language).then(()=>{
      FTImageStore.getExampleEffectList();
    });


    // 调用广告SDK，目前只切换了弹窗广告
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdHeight = () => {
    const dom: any = document.querySelector("#fotorAdWapper");
    const goart_topDom: any = document.querySelector("#goart_top");
    if (dom) {
      if (goart_topDom) {
        dom.style.height = `calc(100% - ${goart_topDom.clientHeight}px - 70px)`;
        dom.style.top = `${goart_topDom.clientHeight}px`;
      } else {
        dom.style.height = `calc(100% - 70px)`;
      }
    }
    handleAd();
  };

  const handleAd = () => {
    if (window.innerWidth < 960) {
      setShowAdInnerWidth(false);
      document.getElementById("fotorAdWapper")!.style.display = "none";
      document.getElementById("fotorAdWapper")!.style.overflow = "hidden";
    } else {
      setShowAdInnerWidth(true);
      if (FTCommonClass.showAd) {
        document.getElementById("fotorAdWapper")!.style.display = "block";
      } else {
        document.getElementById("fotorAdWapper")!.style.display = "none";
      }
    }
  };

  const computedIsMobile = () => {
    let { isMobile, iPad } = utils.myBrowserOS();
    if (isMobile || iPad) {
      FTCanvasStore.setIsMobile(true);
      return;
    }
    let width = window.innerWidth;
    if (width <= defaultLength.MOBILE_WIDTH && !FTCanvasStore.isMobile) {
      FTCanvasStore.setIsMobile(true);
    } else if (width > defaultLength.MOBILE_WIDTH && FTCanvasStore.isMobile) {
      FTCanvasStore.setIsMobile(false);
    }
  };

  const closeAD = () => {
    if (!FTUserStore.userInfo) {
      FTUserStore.showLogin({
        isSignIn: false,
        options: {
          position: "goart_remove_ad"
        },
        isShowScenses: true,
        otherParam: {
          scenesId: "015",
          width: FTImageStore.currentSize.width,
          height: FTImageStore.currentSize.height,
          imgSrc: FTCanvasStore.canvas!.toDataURL("image/jpeg")
        },
        callback: () => {
          closeAD();
        }
      });
    } else {
      if (!FTUserStore.isVip) {
        FTUserStore.showPaymnet({
          vipType: "vip",
          position: "goart_remove_ad",
          otherParam: {
            scenesId: "012"
          },
          callback: () => {
            closeAD();
          }
        });
      }
    }
  };

  const setGoogleAD = () => {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];
    window.googletag.cmd.push(function () {
      window.googletag.pubads().enableAsyncRendering();
      window.googletag.pubads().disableInitialLoad();
    });
    // @ts-ignore
    (adsbygoogle = window.adsbygoogle || []).pauseAdRequests = 1;
    // @ts-ignore
    __tcfapi("addEventListener", 2, function (tcData, success) {
      if (success && tcData.unicLoad === true) {
        if (!window._initAds) {
          window._initAds = true;
          var script = document.createElement("script");
          script.async = true;
          script.src =
            "https://dsh7ky7308k4b.cloudfront.net/publishers/fotorcomhardcoded.min.js";
          script.onload = function () {
            let googletag = window.googletag;

            googletag.cmd.push(function () {
              googletag.display("div-gpt-ad-fotorcom41274");
              googletag.display("fotorcom47758");
              googletag.display("div-gpt-ad-fotorcom41275");
            });
            let event = new CustomEvent("showAds");
            document.dispatchEvent(event);
          };
          document.head.appendChild(script);

          var _script = document.createElement("script");
          _script.async = true;
          _script.src =
            "https://btloader.com/tag?o=5184339635601408&upapi=true";
          document.head.appendChild(_script);
        }
      }
    });
  };

  const testGAReady = async () => {
    initAd.current = true;
    await utils.loadJsScript("https://cmp.uniconsent.com/v2/stub.min.js");
    await utils.loadJsScript("https://cmp.uniconsent.com/v2/48a3eb8636/cmp.js");
    let googleReady = setInterval(() => {
      if (window.googletag) {
        setGoogleAD();
        clearInterval(googleReady);
      }
    }, 1000);
  };

  const EffectLoading = () => {
    return ReactDOM.createPortal(
      // 任何可以渲染的元素，例如一个元素，字符串，或者一个 fragment
      <div className='layout_effect_loading'>
        <FTEffectLoading progress={totalProgress} />
      </div>,
      // 一个 DOM 元素
      document.body
    );
  };

  return (
    <div className='goart_content'>
      <div className='layout'>
        <DialogOpenApp />
        {!isMobile && (
          <div
            style={{
              maxHeight: withUnit(64),
              overflow: "hidden",
              flexShrink: 0
            }}>
            <ADWrapper adId={AD_ID.TOP} type={"top"} />
          </div>
        )}
        <div
          style={{
            flex: 1,
            display: "flex",
            overflow: "hidden"
          }}>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              flex: "1"
            }}>
            <div style={{ maxHeight: "140px", width: "100%", minHeight: "64px" }}>
              <Header />
            </div>

            <div
              className={clsx(
                "layout-content",
                loading && "layout-content-loading",
                isMobile && "layout-content-mobile",
                !currentImage && "layout-content-noimage"
              )}>
              <div
                style={{ display: hasNeedRoot ? "flex" : "none" }}
                className={"goart_content-root_fra"}>
                <div className={"goart_content-root_fra_inner"}>
                  <div
                    onClick={() => {
                      FTImageStore.checkRoot = {
                        ...FTImageStore.checkRoot,
                        need: false
                      };
                      FTImageStore.currentEffect = FTImageStore.prevEffect;
                    }}
                    className={"goart_content-root_fra_inner_close"}>
                    <IconClose />
                  </div>
                  <p className='goart_content-root_fra_inner_titles'>
                    {t("goart_aigc_root_titles")}
                  </p>
                  <p className='goart_content-root_fra_inner_second_titles'>
                    {t("goart_aigc_root_second_titles")}
                  </p>
                  <div id='goart_content-root_fra'></div>
                </div>
              </div>

              {!isMobile && <ToolLeft className='layout-content_left' />}
              <FTCenter
                className='layout-content_canvas'
                showAd={showAdInnerWidth && showAd}
              />
              {isMobile && <ToolLeftMobile className='layout-content_left' />}
            </div>
          </div>
          {!isMobile && showAdInnerWidth && showAd && (
            <div className='goart_ad' style={{ width: "305px", flexShrink: 0 }}>
              <div id='uniconsent-config'>
                <a
                  id='unic-gdpr'
                  onClick={() => {
                    window.__tcfapi("openunic");
                    return false;
                  }}
                  style={{ display: "none", cursor: "pointer" }}>
                  Change Ad Consent
                </a>
                <a
                  id='unic-ccpa'
                  onClick={() => {
                    window.__uspapi("openunic");
                    return false;
                  }}
                  style={{ display: "none", cursor: "pointer" }}>
                  Do not sell my data
                </a>
              </div>
              {ReactDOM.createPortal(
                <div className='goart_ad_btn' onClick={() => closeAD()}>
                  <div className='main__new_delete'>
                    <IconVip />
                    {t("remove_ad_btn")}
                  </div>
                </div>,
                document.body
              )}
            </div>
          )}
        </div>
        {effectLoading && EffectLoading()}
        {!isMobile && downloadVisible && (
          <div className='layout_effect_loading'>
            <FTDownloadLoading
              loading={downloadLoading}
              progress={totalProgress}
            />
          </div>
        )}
        {isMobile && downloadVisible && !downloadSuccess && downloadLoading && (
          <div className='layout_effect_loading'>
            <FTDownloadLoading loading={downloadLoading} />
          </div>
        )}
        {/* {downloadImage && <FTImageDownloadMark />} */}
        {showDownload && isMobile && <FTDownloadMobile />}
      </div>
      {useActivity &&
        ReactDOM.createPortal(
          <ScriptModal type={useActivity} />,
          document.body
        )}
    </div>
  );
}
